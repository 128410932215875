.App {
  text-align: center;
}
.main-page-container {
  overflow-y: auto;
}

.main-page-container.clipped {
  height: 100vh;
  overflow-y: clip;
}
